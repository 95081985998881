import root from './ru';

export default function getMessage(name, dict) {
  if (!name) return;

  for (const message in dict) {
    if (+name === +message) {
      return dict[message];
    }
  }

  if (dict.defaultMessage) {
    return dict.defaultMessage;
  }

  return root.ERROR;
}
