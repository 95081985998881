import cardMessages from '@/helpers/i18n/cardMessages';

import cardStatuses from '@/helpers/i18n/cardStatuses';

import {
  CARD_BINDING,
  CHECK_CARD,
  PAY_REQUEST,
  SET_EMAIL,
  PAYMENT_SMS,
  PAYMENT_CARD_PHOTO, INSURANCE_PAY_IN, INITIAL_LOAN_FINALIZE_BINDCARG,
} from '@/helpers/js/urls';

import dict from '@/helpers/i18n/ru';
import getMessage from '@/helpers/i18n/messages';
import consts from '@/helpers/consts';
import router from '@/router/Router';

function getBankByCardNumber(cardNumber) {
  /*
  * Имена банков присваивались в зависимости от названия иконки
  * */
  const number = cardNumber.slice(0, 2);
  let bank = 'mir';

  switch (+number[0]) {
  case 4:
    bank = 'visa';
    break;
  case 2:
    bank = 'mir';
    break;
  case 5:
    bank = 'mastercard';
    break;
  default:
    bank = 'default-card';
  }

  return bank;
}

const state = {
  cardBindnigStatus: null,
  cardStatus: null,
  cardStatusName: null,
  cardInfo: null,
  cardIcon: null,
  cardMessage: null,
  cardStatusMessage: null,
  mandarinError: null,
  paymentSystem: null,
  paymentCardFormUrl: null,
  cardFormResponseData: null,
  best2PayErrors: null,
  isPaymentSystemActive: true,
  mandarinOperationId: null,
  mandarinLoadFailedError: false,
};
const getters = {
  IS_HAVE_BINDED_CARD: state => state.cardStatus !== 0 && state.cardStatus !== 3,
  mandarinErrorMessage: state => getMessage(state.mandarinError, dict.mandarinErrors),
  isPaymentSystemBest2Pay: state => state.paymentSystem === consts.PAYMENT_SYSTEM.BEST2PAY,
  isPaymentSystemQiwi: state => state.paymentSystem === consts.PAYMENT_SYSTEM.QIWI,
  isPaymentSystemMandarin: state => state.paymentSystem === consts.PAYMENT_SYSTEM.MANDARIN,
  isPaymentSystemHasCardFormUrl: state => state.paymentSystem === consts.PAYMENT_SYSTEM.BEST2PAY
      || state.paymentSystem === consts.PAYMENT_SYSTEM.QIWI,
  getCardFormResponseData: state => state.cardFormResponseData,
  getBest2PayErrors: state => getMessage(state.best2PayErrors, dict.best2PayErrors, true),
};
const actions = {
  async CARD_PHOTO({
    dispatch,
  }, data) {
    await dispatch('API', {
      method: 'post',
      url: PAYMENT_CARD_PHOTO,
      body: data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }, { root: true });
  },
  async GET_OPERATION_ID({
    dispatch,
    commit,
    // eslint-disable-next-line no-shadow
    getters,
  }, payload) {
    const { data: { data } } = await dispatch('API', {
      method: 'post',
      url: CARD_BINDING,
      body: payload,
    }, { root: true });

    commit('SET_PAYMENT_SYSTEM', data.payment_system);

    if (getters.isPaymentSystemHasCardFormUrl) {
      commit('SET_PAYMENT_CARD_FORM', data.url);
    } else {
      commit('SET_MANDARIN_OPERATION_ID', data.jsOperationId);
    }

    return data;
  },
  async PAY_REQUEST({
    dispatch,
    commit,
    // eslint-disable-next-line no-shadow
    getters,
  }, payload) {
    const { data: { data } } = await dispatch('API', {
      method: 'post',
      url: PAY_REQUEST,
      body: payload,
      preloader: true,
    }, { root: true });

    commit('SET_PAYMENT_SYSTEM', data.payment_system);

    if (getters.isPaymentSystemHasCardFormUrl || payload.use_qr_code) {
      commit('SET_PAYMENT_CARD_FORM', data.url);
    } else {
      commit('SET_MANDARIN_OPERATION_ID', data.jsOperationId);
    }

    return data;
  },
  async INSURANCE_PAY_REQUEST({
    dispatch,
    commit,
    // eslint-disable-next-line no-shadow
    getters,
  }, payload) {
    const { data: { data } } = await dispatch('API', {
      method: 'post',
      url: INSURANCE_PAY_IN,
      preloader: true,
      body: payload,
    }, { root: true });

    commit('SET_PAYMENT_SYSTEM', data.payment_system);

    if (getters.isPaymentSystemHasCardFormUrl) {
      commit('SET_PAYMENT_CARD_FORM', data.url);
    } else {
      commit('SET_MANDARIN_OPERATION_ID', data.jsOperationId);
    }

    return data;
  },
  async GET_PAYMENT_SMS({
    dispatch,
  }) {
    return dispatch('API', {
      method: 'get',
      url: PAYMENT_SMS,
    }, { root: true });
  },
  async SEND_PAYMENT_SMS({
    dispatch,
    rootState,
  }, data) {
    await dispatch('API', {
      method: 'post',
      url: PAYMENT_SMS,
      body: data,
    }, { root: true });
    if (rootState.errors.length > 0) {
      for (const error in rootState.errors) {
        if (rootState.errors[error].code === 'expired_sms_code') {
          router.push({
            name: 'LoansList',
          });
        }
      }
    } else if (localStorage.getItem('paymentTimer')) {
      localStorage.removeItem('paymentTimer');
    }
  },
  async SET_EMAIL_PAYMENT({
    dispatch,
  }, data) {
    return dispatch('API', {
      method: 'post',
      url: SET_EMAIL,
      body: data,
    }, { root: true });
  },
  async UNBIND_CARD({ dispatch }, isLoanRequest) {
    await dispatch('API', {
      method: 'post',
      url: isLoanRequest ? INITIAL_LOAN_FINALIZE_BINDCARG : CARD_BINDING,
      body: {
        action_type: 'unbind',
      },
    }, { root: true });
  },
  async CHECK_CARD({
    commit,
    dispatch,
  }, isLoanRequest) {
    const response = await dispatch('API', {
      method: 'get',
      url: isLoanRequest ? INITIAL_LOAN_FINALIZE_BINDCARG : CHECK_CARD,
    }, { root: true });
    if (!response) return;
    commit('CARD_DATA', response.data.data);
  },
  SET_BEST2PAY_CLEAN_ERRORS({ commit }) {
    commit('SET_BEST2PAY_ERRORS', null);
  },
  async SET_PAYMENT_CARD_FORM_RESPONSE_DATA({ state, commit, dispatch }, { data, isLoanRequest }) {
    commit('SET_PAYMENT_CARD_FORM_RESPONSE_DATA', data);

    if (data.code === 'null') {
      data.code = null;
    }

    if (data && (data.code || data.error || data.isError)) {
      commit('SET_BEST2PAY_ERRORS', data.code || data.error || 'default');
      // после безуспешной привязки отвязываем карту и запрашиваем новую ссылку
      await dispatch('CHECK_CARD', isLoanRequest);

      if (state.cardBindnigStatus) {
        await dispatch('UNBIND_CARD', isLoanRequest);
        await dispatch(isLoanRequest ? 'LOAN_REQUEST_GET_OPERATION_ID' : 'GET_OPERATION_ID', {
          action_type: 'bind',
        });
      }
    }

    if (!data) {
      commit('SET_BEST2PAY_ERRORS', null);
    }
  },
  async LOAN_REQUEST_GET_OPERATION_ID({
    dispatch,
    commit,
    // eslint-disable-next-line no-shadow
    getters,
  }, payload) {
    try {
      const { data: { data } } = await dispatch('API', {
        method: 'post',
        url: INITIAL_LOAN_FINALIZE_BINDCARG,
        body: payload,
      }, { root: true });

      commit('SET_PAYMENT_SYSTEM', data.payment_system);

      if (getters.isPaymentSystemHasCardFormUrl) {
        commit('SET_PAYMENT_CARD_FORM', data.url);
      } else {
        commit('SET_MANDARIN_OPERATION_ID', data.jsOperationId);
      }

      return data;
    } catch (rej) {
      return rej.response.data;
    }
  },
};

const mutations = {
  SET_BEST2PAY_ERRORS: (state, data) => {
    state.best2PayErrors = data;
  },
  SET_PAYMENT_CARD_FORM_RESPONSE_DATA: (state, data) => {
    state.cardFormResponseData = data;
  },
  SET_PAYMENT_CARD_FORM: (state, data) => {
    state.paymentCardFormUrl = data;
  },
  SET_MANDARIN_OPERATION_ID: (state, data) => {
    state.mandarinOperationId = data;
  },
  SET_MANDARIN_LOAD_FAILED_ERROR: (state, data) => {
    state.mandarinLoadFailedError = data;
  },
  SET_PAYMENT_SYSTEM: (state, data) => {
    state.paymentSystem = data;
  },
  MANDARIN_ERROR_ID: (state, data) => {
    state.mandarinError = data;
  },
  CARD_DATA: (state, data) => {
    if (!data) {
      state.cardStatus = null;
      state.cardInfo = null;
      state.cardMessage = null;
      state.isPaymentSystemActive = true;
    } else {
      state.cardStatus = data.status;
      state.cardInfo = data.card_info;
      state.cardMessage = data.message !== '' ? data.message : null;
      state.isPaymentSystemActive = data.hasOwnProperty('payment_system_active') ? data.payment_system_active : true;

      if (data.card_info !== null) {
        state.cardIcon = getBankByCardNumber(data.card_info.card_number);
      }

      switch (data.status) {
      case -1:
        state.cardStatusName = cardStatuses.ERROR_BIND;
        state.cardStatusMessage = cardMessages.NEED_CARD_DATA;
        break;
      case 0:
        state.cardStatusName = cardStatuses.HAVE_NO_CARD;
        state.cardStatusMessage = cardMessages.NEED_CARD_DATA;
        break;
      case 1:
        state.cardStatusName = cardStatuses.IN_PROCESS;
        state.cardStatusMessage = cardMessages.IN_PROCESS;
        break;
      case 2:
        state.cardStatusName = cardStatuses.BIND_CARD;
        state.cardStatusMessage = cardMessages.HAVE_CARD;
        break;
      case 3:
        state.cardStatusName = cardStatuses.UNBIND_CARD;
        state.cardStatusMessage = cardMessages.NEED_CARD_DATA;
        break;
      case 4:
        state.cardStatusName = cardStatuses.CARD_LOCK;
        state.cardStatusMessage = cardMessages.NEED_CARD_DATA;
        break;
      case 5:
        state.cardStatusName = cardStatuses.IN_CHECK_PROCESS;
        state.cardStatusMessage = cardMessages.IN_CHECK_PROCESS;
        break;
      case 6:
        state.cardStatusName = cardStatuses.IN_CHECK_PROCESS;
        state.cardStatusMessage = cardMessages.IN_PROCESS;
        break;
      case 7:
        state.cardStatusName = cardStatuses.DENIED;
        state.cardStatusMessage = cardMessages.NEED_CARD_DATA;
        break;
      default:
        break;
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
