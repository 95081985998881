import moment from 'moment';
import store from '@/store';
import { BRAINY_SOFT_REGISTRATION_URL, BRAINY_SOFT_AUTH_URL } from '@/helpers/js/urls';

export function routingLazyLoad(view, folder) {
  return () => import(/* webpackChunkName: "[request]" */ `@/views/${folder || view}/${view}.vue`);
}

export function isEmpty(mixedVar) {
  let index;
  let length;
  const emptyValues = [null, false, ''];

  for (index = 0, length = emptyValues.length; index < length; index++) {
    if (mixedVar === emptyValues[index]) {
      return true;
    }
  }

  if (typeof mixedVar === 'object') {
    for (const key in mixedVar) {
      if (Object.prototype.hasOwnProperty.call(mixedVar, key)) {
        return false;
      }
    }

    return true;
  }

  return false;
}

export function replaceString(string, values) {
  for (const [key, value] of Object.entries(values)) {
    const replace = new RegExp(`{${key}}`, 'g');
    string = string.replace(replace, value);
  }

  return string;
}

export function cloneObject(object) {
  return JSON.parse(JSON.stringify(object));
}

export function convertBoolean(string) {
  return string === 'true';
}

export function findIndexInSortedArray(array, value) {
  let low = 0;
  let high = array.length;

  while (low < high) {
    const mid = (low + high) >>> 1;
    if (array[mid] < value) low = mid + 1;
    else high = mid;
  }

  return low;
}

export function isNumber(value) {
  return value !== null && !Number.isNaN(Number(value));
}

export function isClientDate(date) {
  if (!date || typeof date !== 'string') return false;

  return moment(date, 'DD.MM.YYYY', true).isValid();
}

export function isServerDate(date) {
  if (!date || typeof date !== 'string') return false;

  return moment(date, 'YYYY-MM-DD', true).isValid();
}

export function isClientPhone(phone) {
  if (!phone || typeof phone !== 'string') return false;

  const reg = '^(\\+[0-9])(\\(\\d{3}\\)\\d{3}\\-\\d{2}\\-\\d{2})$';
  const result = phone.match(reg);

  return (result && result.length === 3) || false;
}

export function isServerPhone(phone) {
  const serverPhoneFormatRegexp = /^\+(7|)?(\d{3})(\d{3})(\d{4})$/;

  return serverPhoneFormatRegexp.test(phone);
}

export function calculateAge(birth_month, birth_day, birth_year, startDay, dateIssue = false) {
  const today_date = startDay;// 12121990
  const today_year = today_date.getFullYear();// 1990
  const today_month = today_date.getMonth();// 12
  const today_day = today_date.getDate();// 12
  let age;

  if (dateIssue) {
    age = Math.abs(today_year - birth_year);// 2010 - 1990
  } else {
    age = today_year - birth_year;// 2010 - 1990
  }

  if (!dateIssue) {
    if (+today_month < (birth_month - 1)) {
      age--;
    }
    if (((birth_month - 1) === today_month) && (today_day < birth_day)) {
      age--;
    }
  } else {
    if (+today_month > (birth_month - 1)) {
      age--;
    }
    if (((birth_month - 1) === today_month) && (+today_day > birth_day)) {
      age--;
    }
  }

  return age;
}

export function getCountOfDaysAfterStart(date = null) {
  const day = date ? new Date(date) : new Date();
  return Math.round(day / (1000 * 60 * 60 * 24));
}

export function getCountOfDaysAfterDate(date) {
  return getCountOfDaysAfterStart() - getCountOfDaysAfterStart(date);
}

export function getCountOfDaysBetweenTwoDays(startDate, endDate) {
  return getCountOfDaysAfterStart(endDate) - getCountOfDaysAfterStart(startDate);
}

export function getSum(...args) {
  return args.reduce((total, item) => (item ? +(total + (+item)).toFixed(2) : total), 0);
}

export function getDifference(...args) {
  return args.reduce((total, item) => {
    if (!total) {
      total = 0;
    }

    if (!item) {
      item = 0;
    }

    return +(total - +item).toFixed(2);
  });
}

export function hasOwn(obj, prop) {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

export function getFioString(lastName, firstName, patronymic) {
  return `${lastName} ${firstName}${patronymic ? ` ${patronymic}` : ''}`;
}

export function ObjectToParamsString(params) {
  const str = [];
  for (const p in params) {
    if (params.hasOwnProperty(p) && params[p]) {
      str.push(`${p}=${params[p]}`);
    }
  }
  return str.join('&');
}

export function goToBrainySoft(params, url = null) {
  window.location.href = `${url ? BRAINY_SOFT_AUTH_URL : BRAINY_SOFT_REGISTRATION_URL}?${ObjectToParamsString(params)}`;
}

export function stringParamsToObject(entries) {
  return Object.fromEntries(new URLSearchParams(entries));
}

export async function greylogCollectData(page) {
  const timestamp = Number(localStorage.getItem(page));

  if (timestamp && Math.floor(((new Date().getTime() - timestamp) / (1000 * 60 * 60)) % 24) < 3) {
    localStorage.setItem(page, new Date().getTime().toString());
    return;
  }

  localStorage.setItem(page, new Date().getTime().toString());
  await store.dispatch('GRAYLOG_COLLECT_DATA', { page, url: window?.location?.pathname });
}

export function getPostByCategoryPost(categoryPost) {
  const idValue = categoryPost?.id;

  if (!idValue) {
    return false;
  }

  const idMappings = {
    14: [13, 9, 7],
    6: [11],
    7: [8],
  };

  // Поиск совпадения в объекте сопоставления
  for (const resultValue in idMappings) {
    if (idMappings[resultValue].includes(idValue)) {
      return parseInt(resultValue, 10); // Возвращаем числовое значение
    }
  }
}

export function sendInfoToJivoChat(borrower, environment) {
  const doesChatStarted = localStorage.getItem('jv_store_lVoGb4VVdp_app') && JSON.parse(localStorage.getItem('jv_store_lVoGb4VVdp_app'))?.chat_started;

  if (borrower && window.jivo_api && doesChatStarted) {
    const borrowerName = getFioString(borrower.last_name, borrower.first_name, borrower.patronymic);

    window.jivo_api.setContactInfo({
      name: borrowerName,
      email: borrower.email,
      phone: borrower.phone,
      user_type: environment,
      description: borrower.uuid,
    });

    window.jivo_api.setCustomData([
      {
        title: 'uuid',
        content: borrower?.uuid,
      },
    ]);
  }
}

export function isMobile() {
  return navigator.userAgentData.mobile;
}
